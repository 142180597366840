import React from "react";
import { Link } from "react-router-dom";
import StarRatings from "react-star-ratings";

const MobileProductCart = ({
  product,
  handelWhishList,
  handelSingleProduct,
  handelCart,
  className,
}) => {
  return (
    <div
      key={product._id}
      className={`bg-base-100 rounded shadow-xl ${className && className}`}
    >
      <figure className="relative">
        <div className="h-[200px] overflow-hidden w-full">
          <Link
            to={
              product?.uid
                ? `/singleProduct/sis/${product?.uid}?link=${product?.detailsLink}`
                : `/singleProduct/${product._id}`
            }
          >
            <img
              className="transition ease-in-out delay-150 p-3 cursor-pointer hover:-translate-y-1 hover:scale-110 duration-300"
              src={
                product?.image
                  ? product?.image
                  : `https://api.shopinshop.com.bd/${product.primaryImage}`
              }
              alt={product.productName}
              width="280"
              height="20"
            />
          </Link>
        </div>

        {product.sPrice &&
        product.sPrice !== product?.price &&
        product.price ? (
          <p className="absolute top-2 left-2 text-white text-xs bg-[#2BA968] rounded-full p-[2px]">
            -
            {Math.round(
              parseFloat(
                (100 * (product.price - product.sPrice)) / product.price
              ).toFixed(2)
            )}
            %
          </p>
        ) : (
          ""
        )}
      </figure>
      <div className="">
        <Link
          to={
            product?.uid
              ? `/singleProduct/sis/${product?.uid}?link=${product?.detailsLink}`
              : `/singleProduct/${product._id}`
          }
          className="flex justify-center"
        >
          <h2
            title={product.productName}
            className="hover:text-orange-500 card-title cursor-pointer text-[.6rem] mx-auto"
          >
            {product?.productName.length > 15
              ? product?.productName.slice(0, 15) + ".."
              : product.productName}
          </h2>
        </Link>

        <div className="rating rating-xs flex justify-center mx-auto">
          <StarRatings
            rating={product.avgRating || 0}
            starRatedColor="orange"
            starDimension="15px"
            starSpacing="1px"
          />
        </div>

        {/* {product?.sPrice && product.sPrice !== product?.price ? (
          <div className="text-center">
            <p>
              <span className="line-through text-[.6rem]">
                {Math.round(product.price)} &#2547;
              </span>{" "}
              <span className="font-bold text-[.6rem]">
                {Math.round(product.sPrice)} &#2547;
              </span>{" "}
            </p>
          </div>
        ) : (
          <div className="text-center">
            <p>
              <span className="font-bold text-[.6rem]">
                {Math.round(product.price)} &#2547;
              </span>{" "}
            </p>
          </div>
        )} */}

        {product?.sPrice && product?.sPrice !== product?.price ? (
          <div className="text-center">
            <p>
              <span className="line-through">
                {Math.round(product.price)} &#2547;
              </span>{" "}
              <span className="text-lg font-bold text-[.9rem]">
                {Math.round(product.sPrice)} &#2547;
              </span>{" "}
            </p>
          </div>
        ) : product.price ? (
          <div className="text-center">
            <p>
              <span className="text-lg font-bold text-[.9rem]">
                {Math.round(product.price)} &#2547;
              </span>{" "}
            </p>
          </div>
        ) : product?.color?.length > 0 ? (
          <div className="flex justify-center gap-4">
            {product?.color
              ?.map(
                (item, index) =>
                  item && (
                    <div
                      key={index}
                      className={`h-5 w-5  rounded-full`}
                      style={{ backgroundColor: item }}
                    ></div>
                  )
              )
              ?.slice(0, 5)}
          </div>
        ) : (
          ""
        )}

        <div className="flex mb-2 justify-evenly items-center mt-2">
          <i
            onClick={() => {
              product?.uid
                ? navigate(
                    `/singleProduct/sis/${product?.uid}?link=${product?.detailsLink}`
                  )
                : handelWhishList(product);
            }}
            className="hover:-translate-y-1 hover:scale-110 duration-300 transition ease-in-out delay-150  text-orange-500 cursor-pointer far fa-heart text-md "
          ></i>

          <button
            onClick={() => handelSingleProduct(product._id)}
            className="p-1 rounded bg-primary text-[#fff] text-[.5rem]"
          >
            Buy Now
          </button>
          <i
            onClick={() => {
              product?.uid
                ? navigate(
                    `/singleProduct/sis/${product?.uid}?link=${product?.detailsLink}`
                  )
                : handelCart(product);
            }}
            className="text-md  hover:-translate-y-1 hover:scale-110 duration-300 transition ease-in-out delay-150  text-orange-500 cursor-pointer fas fa-cart-arrow-down"
          ></i>
        </div>
      </div>
    </div>
  );
};

export default MobileProductCart;
