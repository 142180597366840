import React from "react";
import { Link, useNavigate } from "react-router-dom";
import StarRatings from "react-star-ratings";
import { useMedia } from "react-use-media";
import "./ProductCart.css";

const ProductCart = ({
  product,
  handelWhishList,
  handelSingleProduct,
  handelCart,
  isMobile,
  className,
}) => {
  const mobile = useMedia("(max-width: 600px)");
  const navigate = useNavigate();
  return (
    <div
      key={product._id || product?.uid}
      className={`card card-compact bg-base-100
      max-h-[460px]
      shadow-xl ${className ? className : ""}`}
    >
      <figure className="relative">
        <div
          className={
            isMobile
              ? "h-[100%] overflow-hidden flex items-center justify-center w-full"
              : "h-[280px] flex items-center justify-center mt-4 p-2 overflow-hidden w-full"
          }
        >
          <Link
            to={
              product?.uid
                ? `/singleProduct/sis/${product?.uid}?link=${product?.detailsLink}`
                : `/singleProduct/${product._id}`
            }
          >
            <img
              className="transition ease-in-out delay-150 p-3 cursor-pointer hover:-translate-y-1 hover:scale-110 duration-300"
              src={
                product?.image
                  ? product?.image
                  : `https://api.shopinshop.com.bd/${product.primaryImage}`
              }
              alt={product?.productName}
              style={{ maxWidth: "100%", maxHeight: "100%" }}
            />
          </Link>
        </div>

        {product.sPrice &&
        product.sPrice !== product?.price &&
        product.price ? (
          <p className="absolute top-2 left-2 text-white text-sm bg-[#2BA968] rounded-full p-1">
            -
            {Math.round(
              parseFloat(
                (100 * (product.price - product.sPrice)) / product.price
              ).toFixed(2)
            )}
            %
          </p>
        ) : (
          ""
        )}
      </figure>

      <div className="card-body">
        <Link
          to={
            product?.uid
              ? `/singleProduct/sis/${product?.uid}?link=${product?.detailsLink}`
              : `/singleProduct/${product._id}`
          }
          className="flex justify-center mb-0"
        >
          <h2
            title={product?.productName}
            className={
              isMobile
                ? "hover:text-orange-500 card-title flex cursor-pointer text-[.5rem] mx-auto"
                : "hover:text-orange-500 card-title cursor-pointer text-[.8rem] mx-auto"
            }
          >
            {product?.productName.length > 30 && !mobile
              ? product?.productName.slice(0, 30) + "...."
              : product?.productName.length > 20 && mobile
              ? product?.productName.slice(0, 20) + "...."
              : product.productName}
          </h2>
        </Link>

        <div className="rating rating-xs mx-auto">
          <StarRatings
            rating={product.avgRating || 0}
            starRatedColor="orange"
            starDimension="20px"
            starSpacing="1px"
          />
        </div>

        {product?.sPrice && product?.sPrice !== product?.price ? (
          <div className="text-center">
            <p>
              <span className="line-through">
                {Math.round(product.price)} &#2547;
              </span>{" "}
              <span className="text-lg font-bold text-[.9rem]">
                {Math.round(product.sPrice)} &#2547;
              </span>{" "}
            </p>
          </div>
        ) : product.price ? (
          <div className="text-center">
            <p>
              <span className="text-lg font-bold text-[.9rem]">
                {Math.round(product.price)} &#2547;
              </span>{" "}
            </p>
          </div>
        ) : product?.color?.length > 0 ? (
          <div className="flex justify-center gap-4">
            {product?.color
              ?.map(
                (item, index) =>
                  item && (
                    <div
                      key={index}
                      className={`h-5 w-5  rounded-full`}
                      style={{ backgroundColor: item }}
                    ></div>
                  )
              )
              ?.slice(0, 5)}
          </div>
        ) : (
          ""
        )}

        <div className="card-actions justify-evenly items-center mt-2">
          <i
            onClick={() => {
              product?.uid
                ? navigate(
                    `/singleProduct/sis/${product?.uid}?link=${product?.detailsLink}`
                  )
                : handelWhishList(product);
            }}
            className="hover:-translate-y-1 hover:scale-110 duration-300 transition ease-in-out delay-150  text-orange-500 cursor-pointer far fa-heart text-xl "
          ></i>

          <button
            onClick={() =>
              navigate(
                product?.uid
                  ? `/singleProduct/sis/${product?.uid}?link=${product?.detailsLink}`
                  : `/singleProduct/${product?._id}`
              )
            }
            className={
              isMobile
                ? "btn btn-primary  btn-sm text-[.5rem]"
                : "btn btn-primary  btn-sm text-[.7rem]"
            }
          >
            Buy Now
          </button>
          <i
            onClick={() => {
              product?.uid
                ? navigate(
                    `/singleProduct/sis/${product?.uid}?link=${product?.detailsLink}`
                  )
                : handelCart(product);
            }}
            className="text-xl  hover:-translate-y-1 hover:scale-110 duration-300 transition ease-in-out delay-150  text-orange-500 cursor-pointer fas fa-cart-arrow-down"
          ></i>
        </div>
      </div>
    </div>
  );
};

export default ProductCart;
