const buyNow = (buyInfo) => {
  // let shoppingCart = getBuyNow();
  // const quantity = shoppingCart[id];
  // if (quantity) {
  //     const newQuantity = quantity + 1;
  //     shoppingCart[id] = newQuantity;
  // }
  // else {
  //     shoppingCart[id] = 1;
  // }

  localStorage.setItem("buy-now", JSON.stringify(buyInfo));
};

const getBuyNow = () => {
  let buyNowget = {};

  const localStore = localStorage.getItem("buy-now");
  if (localStore) {
    buyNowget = JSON.parse(localStore);
  }

  return buyNowget;
};

const deleteBuyNow = () => {
  localStorage.removeItem("buy-now");
};

export { buyNow, getBuyNow, deleteBuyNow };
