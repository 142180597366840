import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useMedia } from "react-use-media";
// import StarRatings from 'react-star-ratings';
import { cartContext } from "../../App";
import useProducts from "../../Hooks/useProducts";
import Spinner from "../../Shared/Spinner/Spinner";
import { addToCartLocal } from "../../Utilitis/addToCartLocal";
import { addToWishLocal } from "../../Utilitis/AddtoWishList";
import MobileProductCart from "../Cart/MobileProductCart";
import ProductCart from "../Cart/ProductCart";
import Pagination from "../Pagination/Pagination";
const JustForYou = () => {
  const navigate = useNavigate();
  const [datas, isLoading, setCurrentPage, currentPage] = useProducts();
  const carts = useContext(cartContext);
  const [cart, setCartValue, setCart, whishList, setWhishList] = carts;
  setCartValue(datas);
  const [isLoadings, setIsloading] = useState(false);
  const [pageCount, setPageCount] = useState(0);
  const [totalItem, setTotalItem] = useState(0);

  const extraSmall = useMedia("(max-width : 450px)");
  const isMobile = useMedia("(max-width: 700px)");
  const tab = useMedia("(min-width: 700px) and (max-width: 1000px)");
  const largePc = useMedia("(min-width: 1000px) and (min-width: 1800px)");
  // console.log(reviews, rating)

  useEffect(() => {
    window.scrollTo({
      top: 2040,
      left: 0,
      behavior: "smooth",
    });
  }, [currentPage]);
  useEffect(() => {
    setIsloading(true);
    fetch(`https://api.shopinshop.com.bd/product/client/public/count`)
      .then((res) => res.json())
      .then((result) => {
        const count = result.count;
        const pages = Math.ceil(parseInt(count) / 12);
        setPageCount(pages);
        setTotalItem(count);
        setIsloading(false);
      });
  }, []);
  // console.log(datas)

  if (isLoading || isLoadings) {
    return <Spinner></Spinner>;
  }

  const handelCart = (product) => {
    let newCart = [];
    const exists = cart.find((cartProduct) => cartProduct._id === product._id);
    if (!exists) {
      product.quantity = 1;
      newCart = [...cart, product];
    } else {
      const rest = cart.filter(
        (cartProduct) => cartProduct._id !== product._id
      );
      product.quantity += 1;
      newCart = [...rest, exists];
    }

    if (!isLoading) {
      setCart(newCart);
      addToCartLocal(product._id);
    }
  };

  const handelSingleProduct = (id) => {
    navigate(`/singleProduct/${id}`);
  };

  const handelWhishList = (product) => {
    let newWishList = [];
    const exists = whishList.find(
      (cartProduct) => cartProduct._id === product._id
    );
    if (!exists) {
      product.quantity = 1;
      newWishList = [...whishList, product];
    } else {
      const rest = whishList.filter(
        (cartProduct) => cartProduct._id !== product._id
      );
      product.quantity += 1;
      newWishList = [...rest, exists];
    }

    if (!isLoading) {
      setWhishList(newWishList);
      addToWishLocal(product._id);
    }
  };

  // className = "grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-4 my-10";
  return (
    <div className={extraSmall ? "px-1" : "px-5"}>
      <div
        className={`gap-5 mb-10 grid px-5 justify-center ${
          isMobile
            ? "grid-cols-2"
            : largePc
            ? "grid-cols-6"
            : tab
            ? "grid-cols-3"
            : "grid-cols-4"
        }`}
      >
        {datas
          ?.map((product) =>
            extraSmall ? (
              <MobileProductCart
                product={product}
                handelWhishList={handelWhishList}
                handelSingleProduct={handelSingleProduct}
                handelCart={handelCart}
                isMobile={isMobile}
              ></MobileProductCart>
            ) : (
              <ProductCart
                product={product}
                handelWhishList={handelWhishList}
                handelSingleProduct={handelSingleProduct}
                handelCart={handelCart}
                isMobile={isMobile}
              ></ProductCart>
            )
          )
          .reverse()}
      </div>

      {totalItem > 10 && (
        <div className="flex justify-center">
          <Pagination
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            pageCount={pageCount}
          ></Pagination>
        </div>
      )}
    </div>
  );
};

export default JustForYou;
