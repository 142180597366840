import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import InnerImageZoom from "react-inner-image-zoom";
import "react-inner-image-zoom/lib/InnerImageZoom/styles.css";
import { Link, useNavigate, useParams } from "react-router-dom";
import StarRatings from "react-star-ratings";
import { toast } from "react-toastify";
import { useMedia } from "react-use-media";
import ProductCart from "../../Pages/Cart/ProductCart";
import Spinner from "../../Shared/Spinner/Spinner";
import { addToCartLocal, getShoppingCart } from "../../Utilitis/addToCartLocal";
import { buyNow } from "../../Utilitis/buyNow";
import MobileProductCart from "../Cart/MobileProductCart";
import ProductButton from "./ProductButton";
import "./SingleProduct.module.css";
import YoutubeVideo from "./YoutubeVideo";
import axios from "axios";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

const AmazonFlipProduct = () => {
  const nagigate = useNavigate();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [nagigate]);

  const { productId } = useParams();
  const [reviews, setReview] = useState([]);
  const [rating, setRating] = useState(0);
  const [copySuccess, setCopySuccess] = useState("");
  const [pQuentity, setPQuentity] = useState(1);
  const [openOutlet, setOutlet] = useState(false);
  const [cart, setCart] = useState([]);
  const [openVideo, setOpenVideo] = useState(false);
  const [categorySingleProduct, setSingleCategory] = useState([]);
  const extraSmall = useMedia("(max-width : 450px)");
  const isMobile = useMedia("(max-width: 700px)");
  const tab = useMedia("(min-width: 700px) and (max-width: 1000px)");
  const largePc = useMedia("(min-width: 1000px) and (min-width: 1800px)");

  const [productVariation, setProductVariation] = useState([]);
  const [data, setData] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [mainImage, setMainImage] = useState("");
  const [variationLoadig, setVariationLoading] = useState(false);
  const [allVariation, setAllVariation] = useState(false);
  const [otherVariation, setOtherVariation] = useState("");
  const [submitVeriation, setSubmitVeriation] = useState({
    color: "",
    size: "",
  });

  const getProductDetails = async () => {
    try {
      const { data } = await axios.get(
        `https://shopinshopbd.xyz/api/v1/amazon/product-details?id=${productId}`
      );

      if (data?.status === "Accept") {
        setData(data);
        setLoading(false);
      } else {
        setAllVariation(false);
        setLoading(false);
      }
    } catch (err) {
      setLoading(false);

      console.log("get product details error", err?.message);
    }
  };

  const getProductVeration = async () => {
    setVariationLoading(true);
    try {
      const { data } = await axios.get(
        `https://shopinshopbd.xyz/api/v1/amazon/product-details/variation?id=${productId}`
      );
      if (data?.status) {
        const resultArray = [];
        for (const variationName in data?.variation?.colorToAsin) {
          resultArray.push({
            variation_name: variationName,
            image: data?.variation?.colorImages[variationName],
            asin: data?.variation?.colorToAsin[variationName].asin,
          });
        }
        setAllVariation(data?.variation);
        setProductVariation(resultArray);
        setVariationLoading(false);
      } else {
        setProductVariation([]);
        setVariationLoading(false);
        setAllVariation(false);
      }
    } catch (err) {
      console.log("product variation error");
      setProductVariation([]);
      setAllVariation(false);
      setVariationLoading(false);
    }
  };

  useEffect(() => {
    setLoading(true);
    if (productId) {
      getProductDetails();
    }
  }, [productId]);

  useEffect(() => {
    if (!isLoading && data && productId && !data?.variation) {
      getProductVeration();
    }
  }, [data?.variation, productId, isLoading]);

  useEffect(() => {
    if (!isLoading && data && data?.variation) {
      const resultArray = [];
      for (const variationName in data?.variation?.colorToAsin) {
        resultArray.push({
          variation_name: variationName,
          image: data?.variation?.colorImages[variationName],
          asin: data?.variation?.colorToAsin[variationName].asin,
        });
      }

      setProductVariation(resultArray);
      setAllVariation(data?.variation);
    } else {
      setProductVariation([]);
    }
  }, [data?.variation]);

  useEffect(() => {
    setMainImage(data?.primaryImage);
  }, [data]);
  const imgResize = (url) => {
    const x = url.split(".").slice(0, -2);
    const y = x.join(".") + ".jpg";
    setMainImage(y);
  };

  function getMatchingValues(color) {
    const resultArray = [];

    for (const asin in allVariation?.allVariation?.dimensionValuesDisplayData) {
      const values =
        allVariation?.allVariation?.dimensionValuesDisplayData[asin];
      const indexOfColor = values.indexOf(color);

      if (indexOfColor !== -1) {
        const asinIndex = indexOfColor - 1;
        const asinValue = values[asinIndex]
          ? values[asinIndex]
          : values[indexOfColor + 1];

        resultArray.push({
          asin: asin,
          value: asinValue,
          values,
        });
      }
    }

    setOtherVariation(resultArray);
  }

  // useEffect(() => {
  //   const storedCart = getShoppingCart();
  //   const saveCart = [];
  //   for (const id in storedCart) {
  //     const storeProduct = [data]?.find((product) => product?._id === id);
  //     if (storeProduct) {
  //       const quantity = storedCart[id];
  //       storeProduct.quantity = quantity;
  //       saveCart.push(storeProduct);
  //     }
  //   }
  //   setCart(saveCart);
  // }, [productId, pQuentity, data]);
  // const handelSingleProduct = (id) => {
  //   navigate(`/singleProduct/${id}`);
  // };

  // useEffect(() => {
  //   fetch(
  //     `https://api.shopinshop.com.bd/single-product/category/${data?.mainCategoryID[0]?.value}`
  //   )
  //     .then((res) => res.json())
  //     .then((result) => {
  //       setSingleCategory(result);
  //     });
  // }, [data]);

  const handelDiscrese = () => {
    if (+pQuentity > 1) {
      setPQuentity(+pQuentity - 1);
    }
  };
  const handelIncrese = () => {
    if (+pQuentity < +data?.quantity) {
      setPQuentity(+pQuentity + 1);
    }
  };

  const quantityHandeler = (e) => {
    const inputValue = parseInt(e.target.value, 10);

    if (!isNaN(inputValue) && inputValue >= 1 && inputValue <= data.quantity) {
      setPQuentity(inputValue);
    } else {
      toast.error(`Please select less than ${data?.quantity}`);
    }
  };

  const navigate = useNavigate();

  const handelBuyProduct = (event) => {
    event.preventDefault();
    const newQuentity = event.target.qty.value;
    const buyInfo = {
      productId: data?._id,
      newQuentity,
      color: submitVeriation?.color,
      size: submitVeriation?.size,
    };

    if (
      data?.variation &&
      Object.keys(data?.variation?.colorImages).length !== 0
    ) {
      if (submitVeriation?.color && submitVeriation?.size) {
        buyNow(buyInfo);
        navigate(`/checkOut/${data._id}`);
      } else {
        toast.error("Please select color and size");
      }
    } else if (
      data?.variation &&
      Object.keys(data?.variation?.colorImages).length !== 0 &&
      data?.variation?.allVariation?.dimensions?.length == 1
    ) {
      if (submitVeriation?.color || submitVeriation?.size) {
        buyNow(buyInfo);
        navigate(`/checkOut/${data._id}`);
      } else {
        toast.error("Please select color or size");
      }
    } else {
      buyNow(buyInfo);
      navigate(`/checkOut/${data._id}`);
    }
  };

  // const handelCart = (product, refetch) => {
  //   let newCart = [];
  //   const exists = cart.find((cartProduct) => cartProduct._id === product._id);
  //   if (!exists) {
  //     product.quantity = 1;
  //     newCart = [...cart, product];
  //   } else {
  //     const rest = cart.filter(
  //       (cartProduct) => cartProduct._id !== product._id
  //     );
  //     product.quantity += 1;
  //     newCart = [...rest, exists];
  //   }

  //   if (!isLoading) {
  //     setCart(newCart);
  //     addToCartLocal(product._id, refetch);
  //     refetch();
  //   }
  // };

  return (
    <div>
      {isLoading ? (
        <Spinner></Spinner>
      ) : (
        <div>
          <Helmet>
            <meta name="robots" content="index, follow" />
            {/* <meta property="og:title" content={data?.productName} />
            <meta property="og:description" content={data.shortDescription} />
            <meta name="description" content={data.shortDescription} /> */}
            <meta property="og:url" content="shopinshop.com.bd" />
            <meta property="og:image:type" content="image/jpg" />
            <meta property="og:image:width" content="628" />
            <meta property="og:image:height" content="600" />
            <meta property="og:image:alt" content="product Image" />
            {/* <title>Shop In Shop | {data?.productName}</title> */}
          </Helmet>

          <div className="grid grid-cols-1 md:grid-cols-8  mt-10 text-start px-5 md:px-12">
            <div className="col-span-3">
              <div className="flex justify-center md:w-[600px] mx-auto md:h-[400px] rounded-lg">
                <div className="w-full md:w-[400px] md:h-[600px] overflow-hidden ml-2 text-center">
                  {mainImage ? (
                    <InnerImageZoom
                      zoomScale={1}
                      zoomType="hover"
                      zoomPreload={true}
                      fadeDuration={150}
                      hideHint={true}
                      src={mainImage}
                      zoomSrc={mainImage}
                    />
                  ) : (
                    ""
                  )}
                </div>
              </div>

              <div className="flex justify-center mt-2 gap-4">
                {data?.secondImage?.slice(0, 5)?.map(
                  (img, index) =>
                    img &&
                    img.split(".").pop().toLowerCase() !== "gif" &&
                    !img.includes("play-button-overlay-thumb") && (
                      <div
                        onClick={() => imgResize(img)}
                        key={index}
                        className="cursor-pointer"
                      >
                        <img
                          className="border-2 h-[80px] w-[80px] md:w-[80px]"
                          src={img}
                          alt="image"
                        />
                      </div>
                    )
                )}
              </div>
            </div>
            <div className="mt-6 md:mt-0 px-2 col-span-5">
              <h2 className="text-xl md:text-2xl font-semibold capitalize">
                {data?.productName}
              </h2>
              <div className="mt-2 mb-4 flex flex-wrap gap-2 items-center">
                <p className="mt-1 text-muteColor ">
                  {Math.ceil(+data?.rating) || 0}
                </p>
                <StarRatings
                  rating={Math.ceil(+data?.rating) || 0}
                  starRatedColor="orange"
                  starDimension="20px"
                  starSpacing="1px"
                />
                <p className="mt-1 text-muteColor ">{data?.rating_count}</p>
              </div>

              <form onSubmit={handelBuyProduct}>
                <div className="mt-2">
                  {data?.sPrice && data?.price ? (
                    <div className="flex flex-wrap gap-4">
                      <div>
                        <div>
                          <p className="flex gap-3">
                            <span className="text-muteColor text-red-500 font-semibold text-2xl">
                              {Math.floor(
                                ((data?.price - data?.sPrice) / data?.price) *
                                  100
                              )}
                              %
                            </span>
                            <span className="text-2xl font-bold">
                              <sup>&#2547;</sup>
                              {data?.sPrice}
                            </span>
                          </p>
                          <p>
                            <span className="line-through text-muteColor ">
                              M.R.P {data?.price} &#2547;
                            </span>
                            {data?.sale_past_month && (
                              <p className="underline text-orange">
                                {data?.sale_past_month}
                              </p>
                            )}
                          </p>
                        </div>
                        <p>
                          <span className="text-sm">
                            Inclusive of all taxes.
                          </span>
                        </p>
                      </div>
                      <div className=" border-2 rounded m-2 p-3">
                        <p>
                          <span className="font-medium">For Pre order</span>{" "}
                          Estimate Delivery in 12-14 Working Days From India.
                        </p>
                        <p>
                          <span className="font-medium">
                            For Stock Estimate
                          </span>{" "}
                          Delivery in 1-5 Working Days From Dhaka.
                        </p>
                      </div>
                    </div>
                  ) : (
                    <div className="">
                      <p>
                        <span className=" font-bold text-2xl">
                          {data.sPrice} &#2547;
                        </span>
                      </p>
                    </div>
                  )}
                  {data.shortDescription ? (
                    <p className="my-4 pr-4">{data.shortDescription}</p>
                  ) : (
                    ""
                  )}

                  <div className="mt-4">
                    <p>
                      SKU: <span className="font-bold text-sm">{data.sku}</span>
                    </p>

                    {data?.productOverView &&
                    data?.productOverView?.length > 0 ? (
                      <div>
                        <table>
                          <tbody>
                            {data?.productOverView?.map((item, index) => (
                              <tr key={index}>
                                <th className="text-start">{item?.name}</th>
                                <td className="px-4">{""}</td>
                                <td className="ml-4 md:w-[80%]">
                                  {item?.value}
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    ) : (
                      ""
                    )}

                    {variationLoadig ? (
                      <div className="mt-10">
                        <h2 className="font-bold text-xl my-2">
                          Variation : ...{" "}
                        </h2>
                        <div className="grid grid-cols-2 md:grid-cols-5 gap-4">
                          <div>
                            <Skeleton width={150} height={80} />
                          </div>
                          <div>
                            <Skeleton width={150} height={80} />
                          </div>
                          <div>
                            <Skeleton width={150} height={80} />
                          </div>
                          <div>
                            <Skeleton width={150} height={80} />
                          </div>
                        </div>
                      </div>
                    ) : !variationLoadig &&
                      productVariation &&
                      productVariation?.length > 0 ? (
                      <>
                        <h2 className="font-bold text-orange-400 text-xl my-2">
                          {allVariation &&
                            allVariation?.allVariation?.variationDisplayLabels[
                              allVariation?.visualDimensions[0]
                            ]}{" "}
                          :
                        </h2>
                        <div className="grid my-2 grid-cols-2 md:grid-cols-5 gap-3">
                          {productVariation?.map((item, index) => (
                            <div
                              key={index}
                              onClick={() => {
                                setSubmitVeriation({
                                  ...submitVeriation,
                                  color: item?.variation_name,
                                });
                                getMatchingValues(item?.variation_name);
                              }}
                              className={`flex gap-3 items-center bg-white ring-1 ring-black rounded-md cursor-pointer  p-1 ${
                                submitVeriation?.color === item?.variation_name
                                  ? "text-white bg-[#232f3e]"
                                  : "text-black "
                              }`}
                            >
                              <img
                                src={item?.image[0]?.thumb}
                                alt={item?.variation_name}
                                width={50}
                                height={50}
                              />
                              <p>{item?.variation_name}</p>
                            </div>
                          ))}
                        </div>
                      </>
                    ) : (
                      ""
                    )}

                    <div>
                      {otherVariation && (
                        <h2 className="font-bold text-orange-400 text-xl my-2">
                          {allVariation &&
                            allVariation?.allVariation?.variationDisplayLabels[
                              allVariation?.visualDimensions[0] ===
                              allVariation?.allVariation?.dimensions[0]
                                ? allVariation?.allVariation?.dimensions[1]
                                : allVariation?.allVariation?.dimensions[0]
                            ]}
                          :
                        </h2>
                      )}

                      <div className="flex gap-3">
                        {otherVariation &&
                          otherVariation?.map((item, index) => (
                            <div
                              key={index}
                              onClick={() => {
                                setSubmitVeriation({
                                  ...submitVeriation,
                                  size: item?.value,
                                });
                              }}
                              className={`cursor-pointer rounded-md ${
                                submitVeriation?.size === item?.value
                                  ? "text-white bg-[#232f3e]"
                                  : "text-black "
                              }`}
                            >
                              <p className="border px-6 py-2 rounded-md border-black">
                                {item?.value}
                              </p>
                            </div>
                          ))}
                      </div>
                    </div>
                  </div>
                </div>

                <hr className="w-11/12 my-4" />
                <div className="lg:flex items-center">
                  <div className="flex items-center">
                    <div className="flex mx-auto">
                      <div
                        onClick={handelDiscrese}
                        className="flex items-center justify-center cursor-pointer btn text-black hover:text-white p-2 text-xl bg-slate-100 w-10 rounded-lg"
                      >
                        -
                      </div>
                      <input
                        onChange={quantityHandeler}
                        name="qty"
                        type="number"
                        max={data.quantity}
                        value={pQuentity}
                        min="1"
                        className="outline-none text-xl h-10 w-10 mx-2 text-center"
                      />
                      <div
                        onClick={handelIncrese}
                        className="flex items-center justify-center cursor-pointer btn text-black hover:text-white p-2 text-xl bg-slate-100 w-10 rounded-lg"
                      >
                        +
                      </div>
                    </div>
                  </div>

                  <div className="flex mx-auto m-4">
                    <div className="flex mx-auto">
                      <div className="ml-6">
                        <button
                          type="submit"
                          className="btn btn-md btn-primary"
                        >
                          {/* <i className="mr-4 far fa-credit-card" /> */}
                          <p>BUY NOW</p>
                        </button>
                      </div>
                      <div className="ml-6">
                        <span
                          onClick={() => {
                            // handelCart(data, refetch);
                            // navigate("/allProduct");
                          }}
                          className="btn btn-primary w-full"
                        >
                          {/* <i className="mr-4 fas fa-cart-plus" /> */}
                          <p>Add to cart</p>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <hr className="w-11/12 mt-4" />
              </form>
              <div className="flex flex-wrap">
                <div className="mt-4 flex justify-start items-center gap-4">
                  <p className="uppercase font-bold">Copy link : </p>
                  <button
                    onClick={() => {
                      navigator.clipboard.writeText(window.location.href);
                      setCopySuccess("Copied!");
                      setTimeout(() => setCopySuccess(""), 3000);
                    }}
                    className="share-icon"
                  >
                    <i className="text-[20px] hover:text-orange-500 fas fa-link"></i>
                  </button>

                  <p className="text-orange-500">{copySuccess}</p>
                </div>
                <div className="mt-4">
                  <ProductButton></ProductButton>
                </div>
              </div>
            </div>
          </div>

          <div className="px-2 md:px-10 mt-4 mb-10">
            <div dangerouslySetInnerHTML={{ __html: data?.logDescription }} />
            <div dangerouslySetInnerHTML={{ __html: data?.productDetails }} />
            <div
              dangerouslySetInnerHTML={{ __html: data?.productDescription2 }}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default AmazonFlipProduct;
