import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import OrderDetails from "./OrderDetails";
import OrderPdf from "./OrderPdf";
import Review from "./Review";
const SingleOrder = ({ order, refetch }) => {
  const [orderModal, setOrderModal] = useState(null);
  const [reviewModal, setReviewModal] = useState(null);
  const navigate = useNavigate();
  const [pdfs, setPdf] = useState(null);
  // console.log(order)
  const orderCencel = (id) => {
    fetch(`https://api.shopinshop.com.bd/order/cencel/${id}`, {
      method: "PUT",
      headers: {
        authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.acknowledged) {
          toast("Your order has been cancelled");
        }
      });
  };
  return (
    <tr>
      <td>
        <div className="flex items-center space-x-3">
          <div>
            <label
              onClick={() => setOrderModal(order?.products)}
              htmlFor="my-modal-6"
              className="font-bold hover:text-orange-500 cursor-pointer"
            >
              {order.orderID}
            </label>
          </div>
        </div>
      </td>
      <td>
        {order?.totalPayable} &#2547;
        <br />
        <span className="badge badge-ghost badge-sm">Including VAT</span>
      </td>
      <td>{order.date}</td>
      <th>{order?.status}</th>

      <td>
        {order?.payment === "failed" || order?.payment === "Cancel"
          ? "Order Failed"
          : order?.paymentMethod || "COD"}
      </td>
      <th>
        {order?.payment === "failed" || order?.payment === "Cancel" ? (
          <button className="btn btn-primary btn-sm" disabled>
            Download
          </button>
        ) : (
          <label
            onClick={() => setPdf(order)}
            htmlFor="pdf-modal"
            className="btn btn-primary btn-sm"
          >
            Download
          </label>
        )}
      </th>
      {order?.payment === "failed" || order?.payment === "Cancel" ? (
        <td></td>
      ) : (
        <td>
          {order?.status === "Pending" ? (
            <button
              onClick={() => {
                orderCencel(order._id);
                refetch();
              }}
              className="btn btn-primary btn-sm"
            >
              Cancel
            </button>
          ) : order?.status === "Cancel" ? (
            <p className="text-orange-500">Cancel</p>
          ) : order?.status === "Completed" ? (
            <label
              onClick={() => setReviewModal(order)}
              htmlFor="review"
              className="btn btn-primary btn-sm"
            >
              {" "}
              Review
            </label>
          ) : (
            <button
              onClick={() => navigate(`/track-order/${order.orderID}`)}
              className="btn btn-primary btn-sm"
            >
              Track
            </button>
          )}
        </td>
      )}

      {reviewModal && (
        <Review
          reviewModal={reviewModal}
          setReviewModal={setReviewModal}
        ></Review>
      )}
      {orderModal && (
        <OrderDetails
          orderModal={orderModal}
          setOrderModal={setOrderModal}
        ></OrderDetails>
      )}
      {pdfs && <OrderPdf pdfs={pdfs} setPdf={setPdf}></OrderPdf>}
    </tr>
  );
};

export default SingleOrder;
