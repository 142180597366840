import React from "react";
import { useNavigate } from "react-router-dom";

const OrderDetails = ({ setOrderModal, orderModal }) => {
  const navigate = useNavigate();
  return (
    <div>
      <input type="checkbox" id="my-modal-6" className="modal-toggle" />
      <div className="modal modal-bottom ">
        <div className="modal-box w-11/12 max-w-5xl">
          <div className="overflow-x-auto w-full">
            <table className="table w-full">
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Shop Name</th>
                  <th>Price</th>
                  <th>Quantity</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>
                {orderModal?.map((product, index) => (
                  <tr key={index}>
                    <td>
                      <div className="flex items-center space-x-3">
                        <div className="avatar">
                          <div className="mask mask-squircle w-12 h-12">
                            <img
                              src={
                                product?.primaryImageAmazon
                                  ? product?.primaryImageAmazon
                                  : `https://api.shopinshop.com.bd/${product?.productImage}`
                              }
                              alt="Avatar Tailwind CSS Component"
                            />
                          </div>
                        </div>
                        <div>
                          <div
                            onClick={() =>
                              navigate(product?.uid ? `/singleProduct/sis/${product?.uid}` : `/singleProduct/${product.productId}`)
                            }
                            title={product?.productName}
                            className="font-bold cursor-pointer hover:text-orange-500"
                          >
                            {" "}
                            {product?.productName.length > 30
                              ? product?.productName.slice(0, 30) + "...."
                              : product.productName}
                          </div>
                        </div>
                      </div>
                    </td>
                    <td>{product?.shopName}</td>
                    <td>{product?.productPrices} &#2547;</td>
                    <th>{product?.quantity}</th>
                    <td>{product?.status}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          <div className="modal-action">
            <label
              onClick={() => setOrderModal(null)}
              htmlFor="my-modal-6"
              className="btn btn-primary"
            >
              Cancel
            </label>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrderDetails;
